<template>
	<div class="page">
		<div class="page-content">
			<div class="titleBox">
				<p class="title">{{ details.title }}</p>
				<p>{{ details.business_time }}</p>
			</div>
			<div class="content">
				<div class="text" v-html="details.describe"></div>
				<div class="swiper-box">
					<img src="../../../assets/arrows1.png"
						:style="$refs['mySwiper'] ? ($refs['mySwiper'].swiper.realIndex == 0 ? 'opacity: .3;' : '') : ''"
						class="arrows1" @click="swiperPrev()" />
					<img src="../../../assets/arrows1.png" class="arrows1"
						:style="$refs['mySwiper'] ? ($refs['mySwiper'].swiper.realIndex == details.atlas.length ? 'opacity: .3;' : '') : ''"
						@click="swiperNext()" />
					<!-- <viewer :images="details.atlas"> -->
					<swiper :options="swiperOption" ref="mySwiper">
						<swiper-slide class="swiper-slide" v-for="(item, index) in details.atlas" :key="index">
							<!-- <img :src="item" mode="widthFit" class="swpierImg" :key="index" /> -->
							<!-- <img :src="img" mode="widthFit" class="swpierImg" v-for="(img,index) in item"
								:key="index" /> -->
							<el-image :src="item" mode="widthFit" :preview-src-list="details.atlas"
								class="swpierImg"></el-image>
						</swiper-slide>
						<div class="swiper-pagination" slot="pagination"></div>
					</swiper>
					<!-- </viewer> -->
				</div>
				<video :src="details.video" v-if="details.video" controls></video>
			</div>
			<p class="return" @click="$router.go(-1)">
				< {{ lang == 6 ? '返回新闻列表' : 'Back to news list' }}</p>
		</div>

	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper/src/index.js";
	import 'swiper/dist/css/swiper.css';
	import mixinLang from '../../../components/mixin.js'
	export default {
		mixins: [mixinLang],
		components: {
			swiper,
			swiperSlide
		},
		data() {
			return {
				details: {},
				// 轮播配置
				swiperOption: {
					slidesPerView: 3,
					loop: true,
					spaceBetween: 20,
					// autoplay: {
					// 	delay: 3000,
					// 	stopOnLastSlide: false,
					// 	disableOnInteraction: false
					// },
					// 显示分页
					pagination: {
						el: ".swiper-pagination",
						clickable: true //允许分页点击跳转
					},
				}
			}
		},
		methods: {
			swiperPrev(index) {
				var that = this
				that.$refs['mySwiper'].swiper.slidePrev()
			},
			swiperNext() {
				var that = this
				that.$refs['mySwiper'].swiper.slideNext()
			},
			getInfo() {
				this.$api.getArticleDetails({
					id: this.$route.params.id
				}).then(res => {
					// var list = []
					// res.details.atlas = res.details.atlas.split('|')
					// for (var i = 0; i < res.details.atlas.length; i += 3) {
					// 	list.push(res.details.atlas.slice(i, i + 3));
					// }
					// res.details.atlas = list
					// this.details = res.details
					var list = []
					res.details.atlas = res.details.atlas.split('|')
					// for (var i = 0; i < res.details.atlas.length; i += 3) {
					// 	list.push(res.details.atlas.slice(i, i + 3));
					// }
					// res.details.atlas = list
					this.details = res.details
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		position: relative;
		padding: 170px 0 100px;
	}

	.page-content {
		margin: 0 60px;
		background-color: #EEEEEE;
		border-radius: 16px;
		padding: 57px 81px;
	}

	.pageTitleBox {
		padding: 0 111px 50px;

		.titBottom {
			text-transform: uppercase;
		}
	}

	.titleBox {
		font-family: SourceHanSansCN-Normal, sans-serif;
		font-size: 18px;
		font-weight: 350;
		text-align: center;
		padding: 0 100px;

		.title {
			font-family: Montserrat-Bold, sans-serif;
			font-size: 32px;
			font-weight: bold;
			margin-bottom: 30px;
		}

	}

	.content {
		margin: 0 100px;
		border-top: 1px solid transparent;
		border-bottom: 1px solid transparent;
		border-image: url(../../../assets/top/border.png) 30 30 stretch;
		padding: 50px 0;
		margin-top: 40px;

		/deep/ .text {
			font-size: 24px;
			margin-bottom: 30px;
			line-height: 40px;
			font-family: SourceHanSansCN-Normal, sans-serif;
		}
	}

	video {
		width: 100%;
	}

	.swiper-slide {
		display: flex;
		justify-content: space-between;
	}

	.swpierImg {
		width: 100%;
		// height: 100%;
		// width: 540px;
		height: 360px;

		// flex: 1;
		// width: calc(100%/3 - (10px));
		// width: 100%;
	}

	/deep/ .swiper-pagination-bullet {
		width: 15px;
		height: 15px;
		vertical-align: middle;
		margin: 0 10px !important;
	}

	/deep/ .swiper-pagination-bullet-active {
		background-color: #B5A191;
		width: 21px;
		height: 21px;
	}

	/deep/ .swiper-container {
		padding-bottom: 60px;
	}

	.return {
		right: 100px;
		bottom: 107px;
		font-family: SourceHanSansCN-Bold, sans-serif;
		font-size: 24px;
		font-weight: bold;
		cursor: pointer;
		margin-top: 40px;
		text-align: right;
	}

	.breadcrumb-item {
		width: 700px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.swiper-box {
		position: relative;
	}

	.swiper-button-prev,
	.swiper-button-next {
		width: 56px;
		height: 56px;
		background-image: url(../../../assets/arrows1.png);
	}

	.swiper-button-prev {
		left: -60px;
	}

	.swiper-button-next {
		left: auto;
		right: -50px;
		transform: rotate(180deg);
	}

	.arrows1 {
		width: 56px;
		height: 56px;
		position: absolute;
		left: -60px;
		z-index: 9;
		top: calc(50% - (56px/2));

		&:nth-child(2) {
			left: auto;
			right: -50px;
			transform: rotate(180deg);
		}
	}
</style>